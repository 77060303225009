import React from 'react';
import Logo from '../../assets/Logos/logo.png';
import './SummaryView.css';


const summaryView = (props) =>{

    return(
        <div className="SummaryView">
            <div className="SummaryView-ImageHolder">
                <span className="SummaryView-ImageContainer">
                    <span className="SummaryView-ImageItem">
                        <img className="SummaryView-Image" src={Logo} alt="logo"></img>
                    </span>
                </span>

            </div>
            <div className="SummaryView-TextContainer">
                <div className="SummaryView-Title">{props.title}</div>
                <div className="SummaryView-Text">{props.text}</div>
            </div>

        </div>
    );
}

export default summaryView;