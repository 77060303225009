import React from 'react';
import ContactItem from './ContactItem/ContactItem';
import './ContactUs.css';
import image from '../../assets/Logos/logo.png';
import ContactUsSign from '../VisualElements/ContactUsSign/ContactUsSign';

const contactUs = (props) =>{

    return(
        <div className="ContactUs">
            
            <div className="ContactUs-TextContainer">
                <div className="ContactUs-LogoImageHolder">
                    <ContactUsSign
                        image={image}
                    />
                </div>
                <div className="ContactUs-Title">Contact Us</div>
                <div className="ContactUs-Text">We'd love to hear from you</div>
                <div className="ContactUs-ContactsContainer">
                    <ContactItem
                        name=""
                        position=" "
                        email="info@medimynds.com"
                        phone="0522-2222222"
                        linkedinLink="linkedin.com/in/medimynds"
                    />
                </div>
                
            </div>

        </div>
    );
}

export default contactUs;