import React from 'react';
import AtomSignDynamic from '../../VisualElements/AtomSignDynamic/AtomSignDynamic';
import './ResultItem.css';


const resultItem = (props) =>{


    return(
        <div className="ResultItem">
            <div className="ResultItem-ImageContainer">
                <div className="ResultItem-Image">
                    <AtomSignDynamic/>
                </div>
            </div>
            <div>
                <div className="ResultItem-TextTitle">{props.resultTitle}</div>
                <div className="ResultItem-Text">{props.resultText}</div>
            </div>

        </div>
    );
}

export default resultItem;