import React from 'react';
import AboutUsSign from '../VisualElements/AboutUsSign/AboutUsSign';
import './AboutUs.css';


const aboutUs = (props) =>{

    return(
        <div className="AboutUs">
            <div className="AboutUs-ImageHolder">
                <span className="AboutUs-ImageContainer">
                    <span className="AboutUs-ImageItem">
                        <AboutUsSign/>
                    </span>
                </span>

            </div>

            <div className="AboutUs-TextContainer">
                <div className="AboutUs-Title">About Us</div>
                <div className="AboutUs-Text">
                    <div>
                        {/* We focus on the doctor-patient relationship in Telemedicine. <br></br>We structure and facilitate patient-tailored communication that physicians may use to support their clinical decisions, bringing professionalism to the virtual visit.    */}
                        
                        {/* Medyminds focuses on the doctor-patient relationship in Telehealth.<br></br> 
                        Our system facilitates structured patient-tailored communication in the telehealth visit, freeing the physician from the need to asses the psychological state of the patient.<br></br> 
                        This integrates professionalism into the virtual visit, without extra effort on behalf of the physician. */}

                        Medyminds focuses on the doctor-patient relationship in Telehealth. <br></br>
                        Our system facilitates structured patient-tailored communication in the telehealth visit, freeing the physician from the need to asses the psychological state of the patient. This integrates professionalism into the virtual visit, without extra efforts on behalf of the healthcare provider
 
                        
                    </div>                    
                </div>
            </div>

        </div>
    );
}

export default aboutUs;