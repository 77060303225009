import React from 'react';
import './LinkedInIcon.css';

const linkedInIcon = (props) =>{

    let mouseEnterAnimation=(e)=>{
        let targetElement=e.target;
                
        if(targetElement.getElementsByClassName("LinkedInIcon-BaseOne").length>0){
            targetElement.getElementsByClassName("LinkedInIcon-BaseOne")[0].classList.add("LinkedInIcon-BaseOne-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BaseTwo")[0].classList.add("LinkedInIcon-BaseTwo-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BaseThree")[0].classList.add("LinkedInIcon-BaseThree-Trial");

            targetElement.getElementsByClassName("LinkedInIcon-BeamOne")[0].classList.add("LinkedInIcon-BeamOne-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BeamTwo")[0].classList.add("LinkedInIcon-BeamTwo-Trial");
        }
    }

    let mouseLeaveAnimation=(e)=>{
        let targetElement=e.target;
        if(targetElement.getElementsByClassName("LinkedInIcon-BaseOne").length > 0){
            targetElement.getElementsByClassName("LinkedInIcon-BaseOne")[0].classList.remove("LinkedInIcon-BaseOne-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BaseTwo")[0].classList.remove("LinkedInIcon-BaseTwo-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BaseThree")[0].classList.remove("LinkedInIcon-BaseThree-Trial");
        
            targetElement.getElementsByClassName("LinkedInIcon-BeamOne")[0].classList.remove("LinkedInIcon-BeamOne-Trial");
            targetElement.getElementsByClassName("LinkedInIcon-BeamTwo")[0].classList.remove("LinkedInIcon-BeamTwo-Trial");
        }
    }

    return(
        <div className="LinkedInIcon" onMouseEnter={mouseEnterAnimation} onMouseLeave={mouseLeaveAnimation}>
        
             <span className="LinkedInIcon-BeamOne"></span>
             <span className="LinkedInIcon-BeamTwo"></span>

             <span className="LinkedInIcon-BaseOne"></span>
             <span className="LinkedInIcon-BaseTwo"></span>
             <span className="LinkedInIcon-BaseThree"></span>
        </div>
        );
    }
    
    export default linkedInIcon;