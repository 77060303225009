import React from 'react';
import AtomSign from '../VisualElements/AtomSign/AtomSign';
import './OurScience.css';


const ourScience = (props) =>{

    //let sectionText=["A scientifically research-based innovation moving from our digital solution to our AI solution. Medimynds enables to complement the clinical care with psychosocial elements in telehealth."]
    //let sectionText=["A scientifically research-based innovation moving from our digital solution to our AI solution. Based on a decade-long research Medimynds categorizes patients by their psychosocial profile: anxiety, expectations, wellbeing, literacy, demographics, and accords messages that drive medication- adherence to each category.The provider recieves graphic icons of effective and ineffective patient-tailored messages in realtime. Our platform enables to complement the clinical care with psychosocial elements in telehealth."]
    let sectionText=["A scientifically research-based innovation moving from our digital solution to our AI solution. Based on a decade-long research Medimynds categorizes patients by their psychosocial profile: anxiety, expectations, wellbeing, literacy, demographics, and accords messages that drive medication- adherence to each category.The provider recieves graphic icons of effective and ineffective patient-tailored messages in realtime. Our platform enables to complement the clinical care with psychosocial elements in telehealth."];
    
    //let sectionText=["Medimynds enables to complement the clinical care with psychosocial elements in telehealth."]

    return(
        <div className="OurScience">
            <div className="OurScience-ImageHolder">
                <AtomSign/>
            </div>
            <div className="OurScience-TextContainer">
                <div className="OurScience-Title">Our Science</div>
                <div className="OurScience-Text">
                        {sectionText}
                </div>
            </div>

        </div>
    );
}

export default ourScience;