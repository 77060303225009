import React from 'react';
import './AtomSignDynamic.css';


const atomSignDynamic = (props) =>{

    return(
      
        <div className="AtomSignDynamic-ImageHolder">
            <span className="AtomSignDynamic-ImageContainer">
                <span className="AtomSignDynamic-ImageItem">
                    <span className="AtomSignDynamic-ImageItem-VisualElement"></span>
                    <span className="AtomSignDynamic-ImageItem-VisualElement AtomSignDynamic-ImageItem-VisualElement-SpinOne"></span>
                    <span className="AtomSignDynamic-ImageItem-VisualElement AtomSignDynamic-ImageItem-VisualElement-SpinTwo"></span>
                    <span className="AtomSignDynamic-ImageItem-VisualElement AtomSignDynamic-ImageItem-VisualElement-CenterPiece"></span>
                </span>
            </span>
        </div>
      
    );
}

export default atomSignDynamic;