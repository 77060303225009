import React from 'react';
import './ReadMoreButton.css';

import { useState } from 'react';
import PlusMinusSign from '../../../VisualElements/PlusMinusSign/PlusMinusSign';

const ReadMoreButton = (props) =>{

    let classAdditionTwo="ReadMoreButton-Hidden";
    // let longBioItem={};
    
    // let makeText = (array) =>{
    //     let text="";
    //     for (let item in array){
    //         // console.log("$$$",item,array[item])
    //         text=text.concat(' ', array[item])
    //         text=text.concat(' ', '\n')
    //         text=text.concat(' ', '\n')
    //         text=text.concat(' ', '\n')
    //         //console.log("ttt",text)
    //     }
    //     //console.log("ttt",text)
    //     return text
    // }
    
    if(props.longBio !== undefined){
        classAdditionTwo="ReadMoreButton";
        // longBioItem.array=props.longBio.split("** ");
        // if(longBioItem.array.length>1){
        //     longBioItem.text=makeText(longBioItem.array)
        // }
        //console.log("size",longBioItem.array.length)
        //longBioItem.text=makeText(longBioItem.array);
        
        // longBioItem=props.longBio.replace('** ', '<br>');
        
       // console.log(longBioItem);
    }

    // put the usestate thing here
    let [readMoreIndicator, setReadMoreIndicator] = useState(false);

    let changeReadMoreIndicator = () => {
        if(readMoreIndicator===false){
            setReadMoreIndicator(true);
            //console.log("write false");
        }
        else{
            setReadMoreIndicator(false);
            //console.log("write true");
        }
       
    }


    let classAddition="ReadMoreButton-LongBioHolder";
    let buttomTextAddition="Read Less";
    if(readMoreIndicator===false){
        classAddition="ReadMoreButton-LongBioHolder ReadMoreButton-LongBioHolder-Hidden";
        buttomTextAddition="Read More";
    }

    //console.log(props.longBio !== undefined);

    return(
        <div className={classAdditionTwo}> 
            <div className="ReadMoreButton-Button" onClick={changeReadMoreIndicator}>
                <div>
                    <PlusMinusSign isPlus={!readMoreIndicator}/>
                </div>
                <div className="ReadMoreButton-ButtonText">{buttomTextAddition}</div>
            </div>
            <div className={classAddition}>{props.longBio}</div>
            {/* <div className={classAddition}>{props.longBio}{longBioItem}</div> */}
        </div>
    );
}

export default ReadMoreButton;