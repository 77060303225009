import React, {Component} from 'react';
import MainPage from '../../components/MainPage/MainPage';
import NavBar from '../../components/NavBar/NavBar';

// new Mynds Web screens- eliminate the rest later- 2.3.21
import AboutUs from '../../components/AboutUs/AboutUs';
import ContactUs from '../../components/ContactUs/ContactUs';
import OurTeam from '../../components/OurTeam/OurTeam';
import OurResults from '../../components/OurResults/OurResults';
import SummaryView from '../../components/SummaryView/SummaryView';
import OurScience from '../../components/OurScience/OurScience';

class CentralContainer extends Component {

    //////////////////////////////////////////////////
    // State Items
    //////////////////////////////////////////////////
    state={

        scrollPosition:0,
        topicSelected: "",
        presentAdvisoryBoard:false,

    }

    
    #pinstate









    //////////////////////////////////////////////////
    // Mynds Functions
    //////////////////////////////////////////////////
    checkChangeFunction= (soughtItem) =>{
 
        switch (soughtItem) {
            case 'OurScience':
                this.removeAllTopicAnimations();
                document.getElementsByClassName("AtomSign-ImageItem")[0].classList.add("AtomSign-ScaleupAnimation");
              break;
              
            case 'AboutUs':
                this.removeAllTopicAnimations();
                document.getElementsByClassName("AboutUsSign-ImageItem-SmallFigureRight")[0].classList.add("AboutUsSign-MoveRightAnimation");
                document.getElementsByClassName("AboutUsSign-ImageItem-SmallFigureLeft")[0].classList.add("AboutUsSign-MoveLeftAnimation");
                document.getElementsByClassName("AboutUsSign-ImageItem-BigFigure")[0].classList.add("AboutUsSign-ScaleFigureAnimation");
              break;

            case 'OurResults':
                this.removeAllTopicAnimations();
                document.getElementsByClassName("OurResults-ImageHolder")[0].classList.add("OurResults-ImageHolderAnimation");
              break;
            
            case 'ContactUs':
                this.removeAllTopicAnimations();
                document.getElementsByClassName("ContactUsSign-EnvelopeBase")[0].classList.add("ContactUsSign-EnvelopeBase-MoveAnimation");
                document.getElementsByClassName("ContactUsSign-LogoImage")[0].classList.add("ContactUsSign-LogoImage-Altered");
               break;
            
            default:
                this.removeAllTopicAnimations();
          }

    }

    removeAllTopicAnimations= () =>{
        document.getElementsByClassName("OurResults-ImageHolder")[0].classList.remove("OurResults-ImageHolderAnimation");
        document.getElementsByClassName("AtomSign-ImageItem")[0].classList.remove("AtomSign-ScaleupAnimation");
        document.getElementsByClassName("AboutUsSign-ImageItem-SmallFigureRight")[0].classList.remove("AboutUsSign-MoveRightAnimation");
        document.getElementsByClassName("AboutUsSign-ImageItem-SmallFigureLeft")[0].classList.remove("AboutUsSign-MoveLeftAnimation");
        document.getElementsByClassName("AboutUsSign-ImageItem-BigFigure")[0].classList.remove("AboutUsSign-ScaleFigureAnimation");
        document.getElementsByClassName("ContactUsSign-EnvelopeBase")[0].classList.remove("ContactUsSign-EnvelopeBase-MoveAnimation");
        document.getElementsByClassName("ContactUsSign-LogoImage")[0].classList.remove("ContactUsSign-LogoImage-Altered");
    }

    toggleViewedTeamMemebrs= (showAdvisoryBoard) =>{
        
        if(showAdvisoryBoard !== this.state.presentAdvisoryBoard){
            this.setState({
                presentAdvisoryBoard: showAdvisoryBoard
            });
        }

    }

    brandLogoPressed= () =>{
        this.checkScrollTopic(0);
        this.scrollToTopic("SummaryView");    
    }

    checkScrollTopic= (scrollPosition) =>{

        for(let i=0; i<document.getElementsByClassName("NavCategory").length; i++){
            let soughtItem=document.getElementsByClassName("NavCategory")[i].getAttribute("navdestination");
            if(Math.abs(scrollPosition-document.getElementsByClassName(soughtItem)[0].offsetTop)<300){
                this.changeSelectedTopic(soughtItem);
                break;
            }

        }
    }

    changeSelectedTopic= (selectedTopic) =>{
        if(selectedTopic !== this.state.topicSelected){
            this.setState({
                topicSelected: selectedTopic
            });
        }
    }

    scrollToTopic = (destination) =>{

        console.log(destination);

        if(destination !== null){
            window.scroll({
                top: document.getElementsByClassName(destination)[0].offsetTop,
                left: 0,
                behavior: 'smooth'
              });
        };

    }

    #pinfunctions
    
    ////////////////////////////////////////////////////////
    // END OF FUNCTIONS
    ////////////////////////////////////////////////////////






    ///////////////////////////////////////////////////////////
    #pinjsx


    render() {

        let scrollPosition=0;
        window.onscroll = () => {
            if(Math.abs(window.scrollY-scrollPosition)>70){
                scrollPosition=window.scrollY;
                this.checkScrollTopic(scrollPosition);
            }
            
        }

        return(
            <div>
                <NavBar
                    userLoggedIn={this.state.userLoggedIn} 
                    logoutUser={this.logoutUser}
                    showBurgerMenu={this.state.showBurgerMenu}
                    menuOpened={this.state.tabMenuOpen}
                    burgerMenuClicked={this.operateTabMenu}
                    brandLogoPressed={this.brandLogoPressed}

                    scrollToTopic={this.scrollToTopic}
                    topicSelected={this.state.topicSelected}
                    changeSelectedTopic={this.changeSelectedTopic}
                />

                <MainPage>
                    <SummaryView
                        title="MediMynds"
                        //text="We Support doctor-patient communication in Telemedicine to optimize medication-adherence in Type 2 Diabetes and heart failure."
                        text="We offer a platform for patient-tailored communication in telehealth visits that healthcare providers can use to increase medication adherence in type2 diabetes and heart failure."
                    />
                    <OurResults/>
                    <OurScience/>
                    <OurTeam
                        presentAdvisoryBoard={this.state.presentAdvisoryBoard}
                        togglePresentedTeam={this.toggleViewedTeamMemebrs}
                    />
                    <AboutUs/>
                    <ContactUs/>
                     
                </MainPage>
                
            </div>
        );
    }

}

export default CentralContainer;