import React from 'react';
import TeamMember from './TeamMember/TeamMember';
import './OurTeam.css';
import idoImage from '../../assets/StaffImages/IdoImage.png';
import alianaImage from '../../assets/StaffImages/AlianaImage.png';
import limorImage from '../../assets/StaffImages/LimorImage.png';
import glennImage from '../../assets/StaffImages/GlennImage.png';
import gillieImage from '../../assets/StaffImages/GillieImage.png';
import pikkelImage from '../../assets/StaffImages/PikkelImage.png';
import eytanImage from '../../assets/StaffImages/EytanImage.png';
// import howardImage from '../../assets/StaffImages/HowardImage.png';
import zamirImage from '../../assets/StaffImages/ZamirImage.png';
import rubinImage from '../../assets/StaffImages/RubinImage.png';

const ourTeam = (props) =>{

    let togglePresentedTeam=(e)=>{
        
        let showAdvisoryBoard=true;

        if(e.target.getAttribute("topicitem")==="staff"){
            showAdvisoryBoard=false;
        }
        props.togglePresentedTeam(showAdvisoryBoard);

    };

    let advisoryBoardSelectorClasses="OurTeam-Category";
    let teamSelectorClasses="OurTeam-Category OurTeam-Category-Selected";

    if(props.presentAdvisoryBoard===true){
        advisoryBoardSelectorClasses="OurTeam-Category OurTeam-Category-Selected";
        teamSelectorClasses="OurTeam-Category";  
    }

    return(
        <div className="OurTeam">
            <div className="OurTeam-TextContainer">
                <div className="OurTeam-Title">Our Team</div>
                <div className="OurTeam-Categories" onClick={togglePresentedTeam}> 
                    <div topicitem="staff" className={teamSelectorClasses}>Team</div>
                    <div topicitem="advisoryBoard" className={advisoryBoardSelectorClasses}>Advisory Board</div>
                    
                </div>
                <div className="OurTeam-TeamMemberContainer">
                    <TeamMember
                        name="Gillie Gabay, Ph.D"
                        position="CEO (temp.) and CSO"
                        bio="Founder, researcher in the field of doctor-patient       
                        relationship"
                        longBio="Dr. Gabay is a systems science expert.
                        She studies health psychology, the
                        study of psychological and behavioral processes in health and illness. She focuses on clinician-patient communication. She has published in journals such as: BMC medical ethics, Qualitative Health Research, Patient Education and Counseling and published three books on patient and doctors' experiences in health. She is a guest reviewer for several journals."
                        
                        image={gillieImage}
                        show={!props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Limor Benhayun, L.Col (res.)"
                        position="COO"
                        bio="L. Col. (Reserve) in the IDF medical corps"
                        longBio="Limor Ben Hayun is a L.  Col (Reserve) (B.A in Capital Market & HR; M.A. in Health Systems Management),
                        managed the human resources of expert 
                        physicians in the medical corpe of the IDF,  including professional  and personal development, and decisions support. Limor currently works with senior executives in large organizations and has been involved in processes of strategy implementation."
                        image={limorImage}
                        show={!props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Ilyana Rozenberg"
                        position="Health Care Advisor (SME)"
                        bio="Health care advisor"
                        longBio="Ilyana a health care advisor spcializing in SMEs"
                        image={alianaImage}
                        show={!props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Ido Garbi"
                        position="Tech Lead"
                        bio="Sold several patents and lead several development projects for Fortune 500 companies"
                        longBio="Ido Garbi is a text processing expert with a substantial track record. 
                        Ido sold patents to Fortune 500 companies, led projects for Fortune 500 companies, and took part in conception and realization of 5 breakthrough-level inventions, including ones centered around problems which were considered unresolvable.
                        "
                        image={idoImage}
                        show={!props.presentAdvisoryBoard}
                    />

                    <TeamMember
                        name="Zamir O.Z Paz"
                        position="Advisory Board Lead"
                        bio="Visionary Entrepreneur with extensive executive and operational management
                        background"
                        longBio="Visionary Entrepreneur with executive and operational management background, including Bio Medical instrumentation.
                        ** Senior Corporate Executive (CEO, COO, VP R/&amp;D/ Biz Dev/ Eng) with a broad experience in
                        moving companies to the next level. Direct experience in providing leadership
                        to build startups and turn-arounds. Has been involved in several international
                        M/&amp;A/&#39;s. His first founded startup went public (IPO) on NASDAQ (SMMT,
                        Summit Design, Inc.) In 1997. Served as a board member for several public
                        companies."
                        image={zamirImage}
                        show={props.presentAdvisoryBoard}
                    />
                    {/* <TeamMember
                        name="Howard Moskowitz, Ph.D"
                        position="Advisory Board"
                        bio="World-renowned market researcher and psychophysicist"
                        longBio="Howard Moskowitz is an American market researcher and psychophysicist. He is best known for the detailed study he made of the types of horizontal segmentation. By providing a large number of options for consumers, Moskowitz pioneered the idea of intermarket variability as applied to the food industry. Moskowitz graduated from Harvard University in 1969 with a Ph.D. in experimental psychology. Prior to that he graduated Queens College (New York), Phi Beta Kappa, with degrees in mathematics and psychology. He has written/edited sixteen books, has published well over 300 articles and serves on the editorial board of major journals."
                        image={howardImage}
                        show={props.presentAdvisoryBoard}
                    /> */}
                    <TeamMember
                        name="Eytan Roitman, M.D"
                        position="Advisory Board"
                        bio="Practicing diabetologist and the head and founder of institute of diabetes technology and research"
                        longBio="Dr. Roitman is a practicing diabetologist and the head and founder of institute of diabetes technology and research. He previously founded Meir Medical Center’s Clinic for Diabetes Technology, enabling patients to manage their condition with advanced treatment platforms. He also serves as Coordinator of Diabetes Care in the Endocrinology Department at Meir Medical Center. He was the head of the Type 1 Diabetes Clinic for Adults at the Diabetes Medical Center (DMC) in Tel Aviv, providing comprehensive solutions for patients. Dr Roitman was a diabetes consultant to the ambulatory division of Clalit, the largest Israeli health fund and currently working as a diabetes consultant to the Clalit research institute."
                        image={eytanImage}
                        show={props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Dvora Pikkel, D.M.D, Ph.D"
                        position="Advisory Board"
                        bio="Deputy Hospital Director and Senior Management Member of Assuta Hospital"
                        longBio="Dr. Pikkel is the Deputy and Acting Director of Assuta Hospital, and the acting director of the hospital in his absence. Dr. Pikkel manages the daily operation of the hospital. Dr. Pikkel established and manages the risk management and patient safety unit. She oversees all processes of clinical quality at the hospital. She is a member of the Teaching Committee for Medicine at Ben Gurion University. Dr. Pikkel has expertise in cross-organizational change processes to promote quality and in setting up new projects, including implementation, control and conservation."
                        image={pikkelImage}
                        show={props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Rubin S. Cooper, M.D"
                        position="Advisory Board"
                        bio="Chief of Pediatric Cardiology at the Children’s Heart Center at Cohen Children’s Medical Center, Director of the Pediatric Cardiovascular Service Line, Professor of Pediatrics at the
                        Zucker School of Medicine"
                        longBio="Rubin Cooper is a practicing physician with Northwell Health, a leading medical services
                        provider located in New Hyde Park, New York. Rubin has been an outstanding, well-regarded physician for over
                        fifty years with expertise in pediatric cardiology, congenital, rheumatic and Kawasaki Heart
                        Disease, and clinical research. For the past 12 years Rubin has been the Chief of Pediatric
                        Cardiology at the Children’s Heart Center at Cohen Children’s Medical Center of New York,
                        and Director of the Pediatric Cardiovascular Service Line. He is Professor of Pediatrics at the
                        Zucker School of Medicine at Hofstra/Northwell. Early in his career Rubin served as the Chief
                        of Pediatric Cardiology at the Weill Cornell Medical College for ten years and the Pediatric
                        Cardiology Chief at North Shore University Hospital for ten years. Additionally, he held the
                        position of Chief of Pediatric Cardiology at Komansky Children’s Hospital at New York
                        Presbyterian Hospital, Professor of Clinical Pediatrics at the Weill Cornell Medical College,
                        
                        Adjunct Professor at the Columbia School of Surgeons and Physicians. He occupied the Starr-
                        Starlight Foundation Chair in Pediatric Cardiology while at Weill Cornell Medical College. In
                        
                        recognition of his medical excellence, Rubin has been a Castle Connolly America’s Top Top Doctor
                        from 2004-2008 and 2010-2020 and Castle Connolly Top Doctors: New York Metro Area for
                        1999-2008 and 2010-2020. He is a Fellow of the America Academy of Pediatrics, The
                        American College of Cardiology, and The American Heart Association."
                        image={rubinImage}
                        show={props.presentAdvisoryBoard}
                    />
                    <TeamMember
                        name="Glenn Zimmel, M.D"
                        position="US Liaison"
                        bio="Anesthesiologist and pain expert, patient-clinician communication advocate"
                        longBio="Dr. Glenn P. Zemel is an anesthesiologist in Naperville, Illinois and is affiliated with Edward Hospital. He received his medical degree from Wayne State University School of Medicine and has been in practice between 11-20 years. Dr. Zemel provides pain relief during and after an operation, and treats acute and long-standing pain problems believing that the quality of patient-clinician communication is a game changer in patient-adherence."
                        image={glennImage}
                        show={props.presentAdvisoryBoard}
                    />

                </div>
                
            </div>

        </div>
    );
}

export default ourTeam;