import React from 'react';
import logo from '../../assets/Logos/logo.png'
import './NavBar.css';

import NavCategory from './NavCategory/NavCategory';

const navBar = (props) =>{
    let burgerBarHolderClasses="NavBar-Menu-Holder NavBar-Hidden";
    let topicHolderClasses="NavBar-TopicHolder";

    return(
            <div className="NavBar-Main">
                <div className={burgerBarHolderClasses}>

                </div>
                <img className="NavBar-Top-Logo"src={logo} alt="logo" onClick={props.brandLogoPressed}></img>

                <div className={topicHolderClasses}>

                    {/* <NavCategory selected={props.topicSelected==="SummaryView"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="SummaryView" category="MediMynds"/> */}
                    <NavCategory selected={props.topicSelected==="SummaryView"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="SummaryView" category="Glossary"/>
                    <NavCategory selected={props.topicSelected==="OurResults"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="OurResults" category="POC"/>
                    <NavCategory selected={props.topicSelected==="OurScience"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="OurScience" category="Science"/>
                    <NavCategory selected={props.topicSelected==="OurTeam"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="OurTeam" category="Team"/>
                    <NavCategory selected={props.topicSelected==="AboutUs"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="AboutUs" category="About"/>
                    <NavCategory selected={props.topicSelected==="ContactUs"} selectTopic={props.changeSelectedTopic} scrollToTopic={props.scrollToTopic} navDestination="ContactUs" category="Contact"/>

                </div>
                
            </div>

    )
}

export default navBar;