import React from 'react';
import './App.css';
import CentralContainer from '../src/containers/CentralContainer/CentralContainer';

function App() {
  return (
    <div className="App">
      <header className="App-header">

          <CentralContainer/>

      </header>
    </div>
  );
}

export default App;
