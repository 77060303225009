import React from 'react';
import './NavCategory.css';

const navCategory = (props) =>{
    
    let topicClicked = (e) => {
        let navDestination=e.target.getAttribute("navdestination");
        props.scrollToTopic(navDestination);
        props.selectTopic(navDestination);
    }

    let classAddition="NavCategory";
    if(props.selected===true){
        classAddition="NavCategory NavCategory-Selected";
    }

    return(
        <div className={classAddition} onClick={topicClicked} value={props.category} navdestination={props.navDestination}> {props.category}</div>
    )
}

export default navCategory;


    