import React from 'react';
import './AtomSign.css';


const atomSign = (props) =>{

    return(
      
        <div className="AtomSign-ImageHolder">
            <span className="AtomSign-ImageContainer">
                <span className="AtomSign-ImageItem">
                    <span className="AtomSign-ImageItem-VisualElement"></span>
                    <span className="AtomSign-ImageItem-VisualElement AtomSign-ImageItem-VisualElement-SpinOne"></span>
                    <span className="AtomSign-ImageItem-VisualElement AtomSign-ImageItem-VisualElement-SpinTwo"></span>
                    <span className="AtomSign-ImageItem-VisualElement AtomSign-ImageItem-VisualElement-CenterPiece"></span>
                </span>
            </span>
        </div>
      
    );
}

export default atomSign;