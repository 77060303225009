import React from 'react';
import './PlusMinusSign.css';


const plusMinusSign = (props) =>{

    let classAddition="PlusMinusSign-BeamTwo";
    if(props.isPlus===false){
        classAddition="PlusMinusSign-BeamTwo PlusMinusSign-BeamTwo-Hidden"
    }

    return(
      
        <div className="PlusMinusSign">
            <span className="PlusMinusSign-BeamOne"></span>
            <span className={classAddition}></span>
        </div>
      
    );
}

export default plusMinusSign;