import React from 'react';
import './MainPage.css';

const mainPage = (props) =>{
    // let t=()=>{
    //     console.log("3");
    // }

    return(
        <div onClick={props.click} className="MainPage">
            {/* <div className="MainPage-Content" onScroll={t}> */}
            <div className="MainPage-Content">
                 {props.children}
            </div>
               
        </div>
    );
}

export default mainPage;