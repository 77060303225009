import React from 'react';
import './AboutUsSign.css';


const aboutUsSign = (props) =>{

    return(
      
        <div className="AboutUsSign-ImageHolder">
            <span className="AboutUsSign-ImageContainer">
                <span className="AboutUsSign-ImageItem">
                    <span></span>

                    <div className="AboutUsSign-ImageItem-SmallFigureLeft">
                        <span className="AboutUsSign-ImageItem-VisualElement-Body-Small"></span>
                        <span className="AboutUsSign-ImageItem-VisualElement AboutUsSign-ImageItem-VisualElement-Head-Small"></span>                    
                    </div>               

                    <div className="AboutUsSign-ImageItem-SmallFigureRight">
                        <span className="AboutUsSign-ImageItem-VisualElement-Body-Small-Right"></span>
                        <span className="AboutUsSign-ImageItem-VisualElement AboutUsSign-ImageItem-VisualElement-Head-Small-Right"></span>
                    </div>
                    
                    <div className="AboutUsSign-ImageItem-BigFigure">
                        <span className="AboutUsSign-ImageItem-VisualElement-Body"></span>
                        <span className="AboutUsSign-ImageItem-VisualElement AboutUsSign-ImageItem-VisualElement-Head"></span>
                    </div>
                </span>
            </span>
        </div>
      
    );
}

export default aboutUsSign;