import React from 'react';
import './ContactUsSign.css';

const contactUsSign = (props) =>{

    return(
      
        <div className="ContactUsSign">
            <img className="ContactUsSign-LogoImage" src={props.image} alt="logo"></img>

            <span className="ContactUsSign-EnvelopeBase">
                <span className="ContactUsSign-EnvelopeTip"></span>
                <span className="ContactUsSign-EnvelopeSeal">
                    <img className="ContactUsSign-EnvelopeSealImage" src={props.image} alt="little logo"></img>
                </span>
            </span>
        </div>
      
    );
}

export default contactUsSign;