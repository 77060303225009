import React from 'react';
import './OurResults.css';
import ResultItem from './ResultItem/ResultItem';


const ourResults = (props) =>{


    return(
        <div className="OurResults">
            <div className="OurResults-ImageHolder">
                <span className="OurResults-ImageContainer">
                    <span className="OurResults-ImageItem"></span>
                </span>

            </div>
            <div></div>
            <div className="OurResults-TextContainer">
                <div className="OurResults-Title">Proof of Concept</div>
                <div className="OurResults-Text">At a New York based Hospital (90 Days)</div>
                <ResultItem
                    resultTitle="10% Decrease in Cost of Delivery"
                    resultText=" "
                />
                <ResultItem
                    resultTitle="25% Less Re-Admissions"
                    resultText=" "
                />
                <ResultItem
                    resultTitle="2,900,000$ Savings"
                    resultText=" "
                />
            </div>

        </div>
    );
}

export default ourResults;