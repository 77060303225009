import React from 'react';
import './EmailIcon.css';

const emailIcon = (props) =>{

    let mouseEnterAnimation=(e)=>{
        let targetElement=e.target;
                
        if(targetElement.getElementsByClassName("EmailIcon-Top").length>0){
            targetElement.getElementsByClassName("EmailIcon-Top")[0].classList.add("EmailIcon-Top-Trial");
            targetElement.getElementsByClassName("EmailIcon-Seal")[0].classList.add("EmailIcon-Seal-Trial");
        }
    }

    let mouseLeaveAnimation=(e)=>{
        let targetElement=e.target;

        if(targetElement.getElementsByClassName("EmailIcon-Top").length > 0){
            targetElement.getElementsByClassName("EmailIcon-Top")[0].classList.remove("EmailIcon-Top-Trial");
            targetElement.getElementsByClassName("EmailIcon-Seal")[0].classList.remove("EmailIcon-Seal-Trial");
        }
    }

    return(
        <div className="EmailIcon" onMouseEnter={mouseEnterAnimation} onMouseLeave={mouseLeaveAnimation}>
            <span className="EmailIcon-Base"></span>
            <span className="EmailIcon-Top"></span>
            <span className="EmailIcon-Seal"></span>
        </div>
        );
    }
    
    export default emailIcon;