import React from 'react';
import EmailIcon from '../../Generic/DynamicIcons/EmailIcon/EmailIcon';
import LinkedInIcon from '../../Generic/DynamicIcons/LinkedInIcon/LinkedInIcon';
import image from '../../../assets/Logos/logo.png';
import './ContactItem.css';

const contactItem = (props) =>{

    return(
        <div className="ContactItem"> 
            <div className="ContactItem-Title">{props.position}</div>
            <div className="ContactItem-Text">
                <div className="ContactItem-Name">{props.name}</div>
                <div className="ContactItem-DetailsContainer">
                    <div className="ContactItem-Details-IconHolder">
                        <EmailIcon/>
                    </div>
                    <div className="ContactItem-Details">{props.email}</div>
                </div>
                <div className="ContactItem-DetailsContainer">
                    <div className="ContactItem-Details-IconHolder">
                        <LinkedInIcon img={image}/>
                    </div>
                    <div className="ContactItem-Details">{props.linkedinLink}</div>
                </div>
                
            </div>
        </div>
    );
}

export default contactItem;