import React from 'react';
import ReadMoreButton from './ReadMoreButton/ReadMoreButton';
import './TeamMember.css';


const teamMember = (props) =>{

    let classAddition="TeamMember";
    if(props.show===false){
        classAddition="TeamMember TeamMember-Hidden";
    }


    return(
        <div className={classAddition}> 
            <div className="TeamMember-ImageHolder">
                <img src={props.image} className="TeamMember-ImageHolder-Image" alt={props.name}></img>
            </div>
            <div>
                <div className="TeamMember-Name">{props.name}</div>
                <div className="TeamMember-Text">
                    <div className="TeamMember-Title">{props.position}</div>
                </div>
            </div>
            <div className="TeamMember-Details">{props.bio}</div>
            <div className="TeamMember-ReadMore"> 
                <ReadMoreButton longBio={props.longBio} show={false}/>
            </div>
        </div>
    );
}

export default teamMember;